@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: "Permanent Marker", cursive;
    font-weight: 400;
    font-style: normal;
    overflow-x: hidden; /* Horizontal overflow hidden */
  }
  body {
    background-image: url('./backgroundfull.png');
    background-repeat: repeat-y; /* Vertically repeating background image */
    background-size: cover; /* Cover the entire viewport */
    background-attachment: fixed; /* Fixed background image */
    //background-color: #505470;
  }
}

.App {
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Make sure no overflow */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.blurred-border-bottom::after {
  content: "";
  position: absolute;
  bottom: -20px;
  right: -20px;
  left: -20px;
  height: 40px;
  width: 120%;
  overflow-y: hidden;
  background: black ;
  box-shadow: 0 10% 10% rgba(0, 0, 0, 0.3);
  filter: blur(8px);
  z-index: 20;
}

.scaleAnim {
  animation: leaves 6s infinite;
}

@keyframes leaves {
  0% {
    transform: scale(2);
  }
  50% {
    transform: scale(4);
  }
  100% {
    transform: scale(2);
  }
}

.img-hor {
  transform: rotateY(180deg);
}

.img-vert {
  transform: rotateX(180deg);
}