@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    backgroung-image: url('./backgroundfull.png');
    background-repeat: repeat-y; /* Vertically repeating background image */
    background-size: cover; /* Cover the entire viewport */
    background-attachment: fixed; /* Fixed background image */
    background-color: #505470;
    font-family: "Permanent Marker", cursive;
    font-weight: 400;
    font-style: normal;
  }
 
}

.blurred-border-bottom::after {
  content: "";
  position: absolute;
  bottom: -20px;
  right: -20px;
  left: -20px;
  height: 40px;
  width: 120%;
  overflow-y: hidden;
  background: gray;
  box-shadow: 0 10% 10% rgba(0, 0, 0, 0.3);
  filter: blur(8px);
  z-index: 20;
}

.scaleAnim {
  animation: leaves 6s infinite;
}

@keyframes leaves {
  0% {
    transform: scale(2);
  }
  50% {
    transform: scale(4);
  }
  100% {
    transform: scale(2);
  }
}

.img-hor {
  transform: rotateY(180deg);
}

.img-vert {
  transform: rotateX(180deg);
}
